import React from "react";
import OwlCarousel from "react-owl-carousel";
import '../../css/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.green.css';

import placeholder1 from "../../images/placeholder1.jpg";
import newBlogV1 from "../../images/newBlogV1.png";
import polygonAndMooniverse from "../../images/PolygonxMooniverse.png";
import whatIsMooni from "../../images/whatIsMooni.png";
import articleV3 from "../../images/ArticleV3.jpg";

const SectionBlog = () => {
    return(
        <section id="news" className="small_pb" >
            <div className="container" >
                <div className="row" >
                    <div className="col-lg-8 col-md-12 offset-lg-2" >
                        <div className="title_default_light title_border text-center" >
                            <h4 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s" >Latest News</h4 >
                        </div >
                    </div >
                </div >
                <div className="row" >
                    <div className="col-md-12" >
                        <OwlCarousel className="blog_slider owl-theme owl-nav" >
                            <div className="item" >
                                <div className="blog_item animation" data-animation="fadeInUp" data-animation-delay="0.2s" >
                                    <div className="blog_img" >
                                        <a href='https://medium.com/@0xMooniverse/how-to-buy-mooni-on-quickswap-cba0e63ad482' target="_blank" >
                                            <img src={newBlogV1} alt="newBlogV1" />
                                        </a >
                                    </div >
                                    <div className="blog_content" >
                                        <div className="blog_text" >
                                            <h6 className="blog_title" ><a href='https://medium.com/@0xMooniverse/how-to-buy-mooni-on-quickswap-cba0e63ad482' target="_blank" >How to buy MOONI on Quickswap</a ></h6 >
                                            <p >Getting started on the Polygon network might seem like a challenging endeavor but we hope to simplify the process a little bit. 
                                                To start...</p >
                                            <a href='https://medium.com/@0xMooniverse/how-to-buy-mooni-on-quickswap-cba0e63ad482' target="_blank" className="text-capitalize" >Read More</a >
                                        </div >
                                        <ul className="list_none blog_meta" >
                                            <li ><a href='https://medium.com/@0xMooniverse/how-to-buy-mooni-on-quickswap-cba0e63ad482' target="_blank" ><i className="ion-android-time" /> 08/11/2021</a ></li >
                                            {/*<li ><a href='https://medium.com/@0xMooniverse/how-to-buy-mooni-on-quickswap-cba0e63ad482' target="_blank" ><i className="ion-android-chat" /> 0 Comments</a ></li >*/}
                                        </ul >
                                    </div >
                                </div >
                            </div >
                            <div className="item" >
                                <div className="blog_item animation" data-animation="fadeInUp" data-animation-delay="0.4s" >
                                    <div className="blog_img" >
                                        <a href='https://medium.com/@0xMooniverse/how-to-buy-mooni-on-quickswap-v2-45e2708c73ad' target="_blank" >
                                            <img src={whatIsMooni} alt="whatIsMooni" />
                                        </a >
                                    </div >
                                    <div className="blog_content" >
                                        <div className="blog_text" >
                                            <h6 className="blog_title" ><a href='https://medium.com/@0xMooniverse/how-to-buy-mooni-on-quickswap-v2-45e2708c73ad' target="_blank" >How to buy MOONI on QuickswapV2</a ></h6 >
                                            <p >Now with our V2 Article we aim to make the process much easier and way cheaper to accomplish the same task! The following...
                                            </p >
                                            <a href='https://medium.com/@0xMooniverse/how-to-buy-mooni-on-quickswap-v2-45e2708c73ad' target="_blank" className="text-capitalize" >Read More</a >
                                        </div >
                                        <ul className="list_none blog_meta" >
                                            <li ><a href='https://medium.com/@0xMooniverse/how-to-buy-mooni-on-quickswap-v2-45e2708c73ad' target="_blank" ><i className="ion-android-time" /> 08/24/2021</a ></li >
                                            {/*<li ><a href='https://medium.com/@0xMooniverse/how-to-buy-mooni-on-quickswap-v2-45e2708c73ad' target="_blank" ><i className="ion-android-chat" /> 0 Comments</a ></li >*/}
                                        </ul >
                                    </div >
                                </div >
                            </div >
                            <div className="item" >
                                <div className="blog_item animation" data-animation="fadeInUp" data-animation-delay="0.6s" >
                                    <div className="blog_img" >
                                        <a href='https://medium.com/@0xMooniverse/how-to-buy-mooni-on-quickswapv3-coinbase-edition-c76887e91bc4' target="_blank" >
                                            <img src={articleV3} alt="ArticleV3" />
                                        </a >
                                    </div >
                                    <div className="blog_content" >
                                        <div className="blog_text" >
                                            <h6 className="blog_title" ><a href='https://medium.com/@0xMooniverse/how-to-buy-mooni-on-quickswapv3-coinbase-edition-c76887e91bc4' target="_blank" >How to buy MOONI on QuickswapV3</a ></h6 >
                                            <p >We are back again with a new edition on how to purchase MOONI from QuickSwap, this time using Coinbase!
                                            </p >
                                            <a href='https://medium.com/@0xMooniverse/how-to-buy-mooni-on-quickswapv3-coinbase-edition-c76887e91bc4' target="_blank" className="text-capitalize" >Read More</a >
                                        </div >
                                        <ul className="list_none blog_meta" >
                                            <li ><a href='https://medium.com/@0xMooniverse/how-to-buy-mooni-on-quickswapv3-coinbase-edition-c76887e91bc4' target="_blank" ><i className="ion-android-time" /> 07/04/2022</a ></li >
                                            {/*<li ><a href='https://medium.com/@0xMooniverse/how-to-buy-mooni-on-quickswap-v2-45e2708c73ad' target="_blank" ><i className="ion-android-chat" /> 0 Comments</a ></li >*/}
                                        </ul >
                                    </div >
                                </div >
                            </div >
                            <div className="item" >
                                <div className="blog_item animation" data-animation="fadeInUp" data-animation-delay="0.8s" >
                                    <div className="blog_img" >
                                        <a href='https://medium.com/@0xMooniverse/the-future-is-live-on-polygon-see-why-0xmooniverse-will-revolutionize-the-way-we-do-banking-5fc3576bfcb9' target="_blank" >
                                            <img src={polygonAndMooniverse} alt="PolygonAndMooniverse" />
                                        </a >
                                    </div >
                                    <div className="blog_content" >
                                        <div className="blog_text" >
                                            <h6 className="blog_title" ><a href='https://medium.com/@0xMooniverse/the-future-is-live-on-polygon-see-why-0xmooniverse-will-revolutionize-the-way-we-do-banking-5fc3576bfcb9' target="_blank" >The future is LIVE on Polygon</a ></h6 >
                                            <p >0xMooniverse (MOONI) is a revolutionary new token built on the Polygon Network. 
                                                The first of its kind, 0xMooniverse...
                                            </p >
                                            <a href='https://medium.com/@0xMooniverse/the-future-is-live-on-polygon-see-why-0xmooniverse-will-revolutionize-the-way-we-do-banking-5fc3576bfcb9' target="_blank" className="text-capitalize" >Read More</a >
                                        </div >
                                        <ul className="list_none blog_meta" >
                                            <li ><a href='https://medium.com/@0xMooniverse/the-future-is-live-on-polygon-see-why-0xmooniverse-will-revolutionize-the-way-we-do-banking-5fc3576bfcb9' target="_blank" ><i className="ion-android-time" /> 09/12/2021</a ></li >
                                            {/*<li ><a href='/404' target="_blank" ><i className="ion-android-chat" /> 0 Comments</a ></li >*/}
                                        </ul >
                                    </div >
                                </div >
                            </div >
                            {/*<div className="item" >*/}
                            {/*    <div className="blog_item animation" data-animation="fadeInUp" data-animation-delay="0.2s" >*/}
                            {/*        <div className="blog_img" >*/}
                            {/*            <a href='/404' target="_blank" >*/}
                            {/*                <img src={placeholder1} alt="placeholder1" />*/}
                            {/*            </a >*/}
                            {/*        </div >*/}
                            {/*        <div className="blog_content" >*/}
                            {/*            <div className="blog_text" >*/}
                            {/*                <h6 className="blog_title" ><a href='/404' target="_blank" >Coming Soon!</a ></h6 >*/}
                            {/*                <p >Coming Soon!</p >*/}
                            {/*                <a href='/404' target="_blank" className="text-capitalize" >Read More</a >*/}
                            {/*            </div >*/}
                            {/*            <ul className="list_none blog_meta" >*/}
                            {/*                <li ><a href='/404' target="_blank" ><i className="ion-android-time" /> August 14, 2021</a ></li >*/}
                            {/*                <li ><a href='/404' target="_blank" ><i className="ion-android-chat" /> 0 Comments</a ></li >*/}
                            {/*            </ul >*/}
                            {/*        </div >*/}
                            {/*    </div >*/}
                            {/*</div >*/}
                        </OwlCarousel >
                    </div >
                </div >
            </div >
        </section >
    )
}

export default SectionBlog;