import React, {useState} from 'react';
import {ethers} from 'ethers';
// import { createAlchemyWeb3  } from '@alch/alchemy-web3';
import MooniverseABI from '../artifacts/contracts/MooniverseABI.json';
import BalanceMessage from './BalanceMessage';
import MessageForSend from "./MessageForSend";
import {MooniverseProps} from "../../../js/App";
import {Link} from "react-scroll";

const Faucet = () => {
    //user balance
    const [balance, setBalance] = useState();
    const [showBalance, setShowBalance] = useState(false);
    
    // Was there an error with the users balance request
    const [balanceErrorMessage, setBalanceErrorMessage] = useState('');
    const [didBalanceError, setDidBalanceError] = useState(false);
    
    // Message for the get tokens request and if it errored
    const [message, setMessage] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [hadError, setHadError] = useState(false);
    
    // The entered user account
    const [userAccount, setUserAccount] = useState();
    
    // Faucet balance
    const [faucetAmount, setFaucetAmount] = useState();
    const [showFaucetBalance, setShowFaucetBalance] = useState(false);
    const [checkFaucetBalance, setCheckFaucetBalance] = useState(true);

    // const alchemyWeb3 = createAlchemyWeb3(MooniverseProps.REACT_APP_POLYGON_RPC_API_Key);
    // const alchemyMooniverseContract = new alchemyWeb3.eth.Contract(MooniverseABI, MooniverseProps.REACT_APP_MOONIVERSE_CONTRACT);
    
    
    /// request access to the user's MetaMask account
    const requestAccount = async () => {
        await window.ethereum.request({method: 'eth_requestAccounts'});
    }

    /// Sends coins to a inputted account
    const sendCoins = async () => {
        if (typeof window.ethereum !== 'undefined') {
            try{
                // Check to see if the user inputted a valid wallet
                if(!ethers.utils.isAddress(userAccount)){
                    setMessage('Please enter a valid address and try again.');
                    setHadError(true);
                    setShowMessage(true);
                    return;
                }
                
                // Requesting user account
                await requestAccount()

                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const network = await provider.getNetwork()
                
                // const signedTx = await alchemyWeb3.eth.accounts.signTransaction(transaction, PRIVATE_KEY);
                
                
                // Checking the users current chain
                // Matic chainId = 137 and mumbai = 80001
                if(network.chainId !== parseInt(MooniverseProps.REACT_APP_CHAIN_ID)) {
                    setMessage('You are connected to the wrong network! Please connect to the Polygon mainnet and try again.');
                    setHadError(true);
                    setShowMessage(true);
                    return;
                }
               
                // Setting up our contract
                const signer = await provider.getSigner();
                const mooniverseContract = new ethers.Contract(MooniverseProps.REACT_APP_MOONIVERSE_CONTRACT, MooniverseABI, signer);
                const signerAddress = await signer.getAddress();
                
                // Checking to make sure we have everything we need to send tokens
                if(userAccount && signer && signerAddress && signerAddress === userAccount){
                    // Call the contract to get our needed pre send values to prevent errors
                    const faucetCheckTrx = await mooniverseContract.balanceOf(MooniverseProps.REACT_APP_MOONIVERSE_CONTRACT);
                    const faucetTransferAmountTrx = await mooniverseContract.faucetTransferAmount();
                    const userAccessCheckTrx = await mooniverseContract.allowedToWithdraw(userAccount);
                    const faucetHasEnoughTokens = convertNumberForComparison(faucetCheckTrx) >= convertNumberForComparison(faucetTransferAmountTrx);

                    // let serializedTx = mooniverseContract.serialize();
                    
                    // Error checking
                    if(!faucetHasEnoughTokens){
                        setMessage('Faucet does not have enough tokens to send.');
                        setHadError(true);
                    }
                    else if(userAccessCheckTrx === false){
                        setMessage('Already accessed the faucet. Please wait 24 hours from your last access and try again.');
                        setHadError(true);
                    }
                    else{
                        // Should be no errors at this point. Call the contract and send the tokens
                        const transaction = await mooniverseContract.activateFaucet(); // need to await the result
                        // const transaction = await alchemyWeb3.eth.sendSignedTransaction(mooniverseContract.activateFaucet()); // need to await the result
                        await transaction.wait(); // Need to wait for writable transactions

                        setHadError(false);
                        setMessage(`${convertNumberToReadableString(faucetTransferAmountTrx)} Tokens successfully sent to ${getShortenedWallet(userAccount)}.`);
                        setCheckFaucetBalance(true);
                    }
                }
                // More error checking
                if(!userAccount){
                    setMessage('Please enter a valid address and try again.');
                    setHadError(true);
                }
                else if(userAccount !== signerAddress){
                    setMessage('The address does not match the current signer address. ');
                    setHadError(true);
                }

                setShowMessage(true);
            }
            catch(ex){
                // code = 4001 should be a user denied error. At least with metamask.
                if(ex && ex.code && ex.code === 4001)
                    setMessage(ex.message);
                else if(ex && ex.message && (ex.message.toLowerCase().includes('user denied') || ex.message.toLowerCase().includes('user rejected')))
                    setMessage(ex.message);
                else if(ex && ex.data && ex.data.message)
                    setMessage(ex.data.message);
                else
                    setMessage('There was an error with the faucet. Please check your wallet and RPC settings and try again.');

                setHadError(true);
                setShowMessage(true);
            }
        }
        else{
            setMessage('Please install a Web3 wallet and try again.');
            setHadError(true);
            setShowMessage(true);
        }
    }
    
    /// Gets an inputted wallets balance of Mooniverse
    const getBalance = async () => {
        // if (typeof window.ethereum !== 'undefined') {
            try{
                if(!ethers.utils.isAddress(userAccount)){
                    setBalanceErrorMessage('Please enter a valid address and try again.');
                    setDidBalanceError(true);
                    return;
                }
                
                // Getting our contract details
                const url = MooniverseProps.REACT_APP_POLYGON_RPC_API_Key;
                const network = { chainId: parseInt(MooniverseProps.REACT_APP_CHAIN_ID) };
                const customHttpProvider = new ethers.providers.JsonRpcProvider(url, network);
                const contract = new ethers.Contract(MooniverseProps.REACT_APP_MOONIVERSE_CONTRACT, MooniverseABI, customHttpProvider)
                const balance = await contract.balanceOf(userAccount);
                
                // logging and showing their balance
                console.log("Balance: ", balance.toString());
                setBalance(balance.toString());
                setShowBalance(true);
                
                setDidBalanceError(false);
                setBalanceErrorMessage('');
            }
            catch(ex){
                // // code = 4001 should be a user denied error. At least with metamask.
                // if(ex && (ex.code && ex.code === 4001) || (ex.message && (ex.message.includes('User denied') || ex.message.toLowerCase().includes('user rejected'))))
                //     setBalanceErrorMessage(ex.message);
                // else if(ex && ex.data && ex.data.message)
                //     setBalanceErrorMessage(ex.data.message);
                // else
                console.log(ex);
                setBalanceErrorMessage('There was an error with the faucet. Please check your wallet and RPC settings and try again.');
                setDidBalanceError(true);
            }
        // }
        // else{
        //     setMessage('Please install a Web3 wallet try again.');
        //     setHadError(true);
        //     setShowMessage(true);
        // }
    }

    /// Used to check our faucet token supply. This is used to prevent errors when dealing with "Big Number" objects
    const convertNumberForComparison = (balanceAmount) => {
        if(balanceAmount){
            let balanceString = balanceAmount.toString();
            let updatedBalance = balanceString.slice(0, balanceString.length - 9) + '.' + balanceString.slice(-9, balanceString.length - 7);

            return Number(parseFloat(updatedBalance).toFixed(2));
        }
    }
    
    /// Creates a nicely formatted number to display with 9 decimals
    const convertNumberToReadableString = (balanceAmount) => {
        if(balanceAmount){
            let balanceString = balanceAmount.toString();
            let updatedBalance = balanceString.slice(0, balanceString.length - 9) + '.' + balanceString.slice(-9, balanceString.length);

            return Number(parseFloat(updatedBalance).toFixed(9)).toLocaleString('en', {minimumFractionDigits: 9});
        }
    }
    
    /// Gets a shortened wallet to display for the user
    const getShortenedWallet = (wallet) => {
        if(wallet)
            return wallet.slice(0, 6) + '....' + wallet.slice(-4, wallet.length);
    }

    /// Gets our faucets balance
    const getFaucetBalance = async () => {
        const url = MooniverseProps.REACT_APP_POLYGON_RPC_API_Key;
        const network = { chainId: parseInt(MooniverseProps.REACT_APP_CHAIN_ID) };
        const customHttpProvider = new ethers.providers.JsonRpcProvider(url, network);
        const contract = new ethers.Contract(MooniverseProps.REACT_APP_MOONIVERSE_CONTRACT, MooniverseABI, customHttpProvider)
        const balance = await contract.balanceOf(MooniverseProps.REACT_APP_MOONIVERSE_CONTRACT);
        
        // TODO add message if statement here to see if faucet balance is greater than the send balance for the faucet. if not then add error message saying "Sorry the faucet is dry"
        
        setFaucetAmount(balance.toString());
        setShowFaucetBalance(true);
        setCheckFaucetBalance(false);
    }

    React.useEffect(() => {
        const checkAndGetBalance = async () => {
            if(checkFaucetBalance)
                await getFaucetBalance()
        }
        checkAndGetBalance();
       
    }, [checkFaucetBalance]);
    
    return (
        <div className="container" >
            <div className="row" >
                <div className="col-lg-4 col-md-12 col-sm-12" >
                    <div className=" text-center animation" data-animation="fadeInUp" data-animation-delay="0.6s" >
                        <Link style={{color: '#ffffff'}} onClick={getBalance} className="btn btn-default btn-radius nav_item" to="" spy={true} smooth={true} offset={-70} duration={150} >Check balance</Link >
                        {
                            showBalance && !didBalanceError
                                ? <div><br/><BalanceMessage balance={convertNumberToReadableString(balance)}/></div> 
                                : didBalanceError
                                    ? <div><br/><MessageForSend message={balanceErrorMessage} success={!didBalanceError}/></div>
                                    : <div><br/></div>
                        }
                    </div >
                </div >
                <br/>
                <div className="col-lg-4 col-md-12 col-sm-12" >
                    <div className=" text-center animation" data-animation="fadeInUp" data-animation-delay="0.8s" >
                        <input onChange={e => setUserAccount(e.target.value)} placeholder="Enter your 0x address" />
                        {
                            showFaucetBalance
                                ? <div><br/><BalanceMessage balance={convertNumberToReadableString(faucetAmount)} message='Remaining Faucet Balance' /></div>
                                : <div/>
                        }
                        <br/>
                    </div >
                </div >
                <div className="col-lg-3 col-md-12 col-sm-12" >
                    <div className=" text-center animation" data-animation="fadeInUp" data-animation-delay="0.6s" >
                        <Link style={{color: '#ffffff'}} onClick={sendCoins} className="btn btn-default btn-radius nav_item" to="" spy={true} smooth={true} offset={-70} duration={150} >Activate Faucet</Link >
                        {
                            showMessage
                                ? <div><br/><MessageForSend message={message} success={!hadError}/></div>
                                : <div><br/></div>
                        }
                        <br/>
                    </div >
                </div >
            </div >
        </div >
    )
}

export default Faucet






   
        

