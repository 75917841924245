import React from "react";
import service_icon1 from "../../images/CommunityIcon.png";
import service_icon2 from "../../images/MiningIcon.png";
import service_icon3 from "../../images/DeflationaryIcon.png";
import service_icon4 from "../../images/InflationaryIcon.png";
import service_icon5 from "../../images/EconomicIcon.png";
import service_icon6 from "../../images/RevolutionaryIcon.png";

const SectionTokenomics = () => {
    return(
        <section id="tokenomics" className="small_pb small_pt "  >
            <div className="container" >
                <div className="row align-items-center" >
                    <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12" >
                        <div className="title_default_light title_border text-center" >
                            <br/>
                            <h4 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s" >Tokenomics</h4 >
                            <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s" >
                                There are many reflection tokens being created that are all just copy/pasted code.<br/> This isn't that. 
                            </p >
                        </div >
                    </div >
                </div >
                <div className="row" >
                    <div className="col-lg-4 col-md-6 col-sm-12" >
                        <div className="box_wrap text-center animation" data-animation="fadeInUp" data-animation-delay="0.6s" >
                            <img src={service_icon1} alt="service_icon1" />
                            <h4 >Community</h4 >
                            <p >Mooniverse was built with the community in mind. There is no insane tax of 10% or more per transfer. With every transfer, 
                                ONLY 2% is taken and distributed among token holders as well as the burn wallet. 
                                This means you get more of what you deserve and lose less when interacting with Mooniverse!</p >
                        </div >
                    </div >
                    <div className="col-lg-4 col-md-6 col-sm-12" >
                        <div className="box_wrap text-center animation" data-animation="fadeInUp" data-animation-delay="0.8s" >
                            <img src={service_icon2} alt="service_icon2" />
                            <h4 >Token Mining</h4 >
                            <p >One of the core tenents that makes Mooniverse different is the built in communal mining process. Mining only happens when someone transfers, buys or sells tokens. 
                                Because free tokens are minted and distributed to holders, this helps incentivize people to continue to trade and interact with the protocol.
                            </p >
                        </div >
                    </div >
                    <div className="col-lg-4  col-md-6 col-sm-12" >
                        <div className="box_wrap text-center animation" data-animation="fadeInUp" data-animation-delay="1s" >
                            <img src={service_icon5} alt="service_icon5" />
                            <h4 >Economic</h4 >
                            <p >Created for an ever-changing economy, Mooniverse was built with an automatic circulating supply check and is able to ensure that the number of tokens available is 
                                more than the set threshold amount. By automatically including and excluding the burn wallet, this prevents the circulating supply from dwindling down to near 0 tokens like most reflection tokens.</p >
                        </div >
                    </div >
                    <div className="col-lg-4 col-md-6 col-sm-12" >
                        <div className="box_wrap text-center animation" data-animation="fadeInUp" data-animation-delay="1s" >
                            <img src={service_icon4} alt="service_icon4" />
                            <h4 >Deflationary</h4 >
                            <p >Mooniverse uses a burn mechanism in order to deflate the supply of tokens. This means that when the Genesis block was mined, 48% of the 1 trillion supply was burned. So, for every transfer thereafter, 
                                along with the mining of tokens due to those transfers, roughly 1% is distributed back into the community and roughly 1% is burned. </p >
                        </div >
                    </div >
                    <div className="col-lg-4 col-md-6 col-sm-12" >
                        <div className="box_wrap text-center animation" data-animation="fadeInUp" data-animation-delay="1s" >
                            <img src={service_icon3} alt="service_icon3" />
                            <h4 >Inflationary</h4 >
                            <p >With every block that passes after the Mooniverse genesis block, 1272 tokens are added per block to the total supply. Because the token is built on Polygon, 
                                where there is 1 block created every 2 seconds, the total number of tokens minted over a 365-day period comes out to 20,056,896,000 where 48% or more is burned
                                based on the burn wallets supply. </p >
                        </div >
                    </div >
                    <div className="col-lg-4 col-md-6 col-sm-12" >
                        <div className="box_wrap text-center animation" data-animation="fadeInUp" data-animation-delay="1s" >
                            <img src={service_icon6} alt="service_icon6" />
                            <h4 >Revolutionary</h4 >
                            <p >By design, Mooniverse was created to change the way we view the market by deflating the supply as more members of the community trade
                                during bull markets and by inflating the supply during bear markets when trading volume dissipates. This is achieved by rewarding community interaction with the 
                                protocol with newly minted tokens that can then be held to generate even more Mooniverse or sold for a profit! 
                            </p >
                        </div >
                    </div >
                </div >
            </div >
        </section >
    )
}

export default SectionTokenomics;