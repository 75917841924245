// import about_img2 from "../../images/about_img2.png";
import React from 'react';

const SectionAboutUs = () => {
    return(
        <section id="about" className="small_pt" >
            <div className="container" >
                <div className="row align-items-center" >
                    
                    
                    {/*<div className="col-lg-12 col-md-12 col-sm-12" >*/}
                    {/*    <div className="text_md_center" >*/}
                    {/*        <br/>*/}
                    {/*        <br/>*/}
                    {/*        <img className="animation" data-animation="zoomIn" data-animation-delay="0.2s" src={about_img2} alt="aboutimg2" />*/}
                    {/*    </div >*/}
                    {/*</div >*/}
                    
                    <div className="col-lg-12 col-md-12 col-sm-12 res_md_mt_30 res_sm_mt_20" >
                        <div className="title_default_light title_border text-center" >
                            <br/>
                            <h4 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s" >About Mooniverse</h4 >
                            <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s" >
                                Mooniverse, or Mooni for short, is the <b>FIRST</b> hybrid inflationary/deflationary reflection token ever created and was deployed on Polygon for its extremely low fees, fast transactions,
                                and fast block creation times. It utilizes brand new technology to help make the token effective during all market cycles. The idea behind this was to create a token
                                that would thrive during bull markets and bring its holders interest for just holding the token. This allows for the holders to profit by either selling their rewarded tokens 
                                or by holding it and allowing it to generate them even more tokens!
                            </p >
                            <p className="animation" data-animation="fadeInUp" data-animation-delay="0.6s" >
                                But the problem with all crypto currencies is the downtrend of Bitcoin and the ensuing bear market. To help offset and mitigate this 
                                when the bear market does inevitably arrive, 0xMooniverse has a token mining process built directly into the protocol that can only be activated when the community
                                members buy, sell, or transfer tokens. The contract is designed to mint 1,272 tokens per block which comes out to about 20,056,896,000 newly minted tokens a year, where
                                roughly 48% or more is burned. This incentivizes holders to continuously hold their current supply and to interact with the 0xMooniverse contract in order to trigger the 
                                communal mining process which will then reward the whole community with more tokens which, in turn, can then be sold for profit which would distribute even more tokens 
                                to the community or held to generate even more interest and because this process effectively burns more of the existing circulating supply of tokens, it drives 
                                the burn supply percentage higher which then deflates more of the circulating supply even faster helping to drive prices higher!
                            </p >
                            <p className="animation" data-animation="fadeInUp" data-animation-delay="0.7s" >
                                <b>This is 0xMooniverse. Welcome Home.</b>
                            </p>
                        </div >
                        <a href="https://quickswap.exchange/#/swap?outputCurrency=0x1Cf19b5C198B1Fb229C1DB51Ee5FceCc28981f4E" target="_blank" rel='noreferrer' className="btn btn-default btn-radius video animation" data-animation="fadeInUp" data-animation-delay="1s" >Buy Now <i className="ion-ios-arrow-thin-right" /></a >
                        <br/>
                        <br/>
                        <h5 style={{fontSize: "1.1vh"}} className="animation" data-animation="fadeInUp" data-animation-delay="0.8s" >
                            There is risk associated with every investment and your capital is at risk. Do your own research.
                        </h5 >
                    </div >
                </div >
            </div >
        </section >
    )
}

export default SectionAboutUs;