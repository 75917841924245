import OwlCarousel from "react-owl-carousel";
import React from "react";
import '../../css/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.green.css';

const SectionRoadMap = () =>{
    return(
        <section id="roadmap" className="small_pb" >
            <div className="container" >
                <div className="row text-center" >
                    <div className="col-lg-8 col-md-12 offset-lg-2" >
                        <div className="title_default_light title_border text-center" >
                            <h4 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s" >Roadmap</h4 >
                            <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s" />
                        </div >
                    </div >
                </div >
            </div >
            <div className="container" >
                <div className="row" >
                    <div className="col-md-12" >
                        <OwlCarousel className="owl-theme timeline" >
                            <div className="item" >
                                <div className="timeline_box complete" >
                                    <div className="timeline_inner" >
                                        <div className="timeline_circle" />
                                        <h6 className="animation" data-animation="fadeInUp" data-animation-delay="0.3s" >August 2021</h6 >
                                        <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s" >Website Created & Deployed</p >
                                    </div >
                                </div >
                            </div >
                            <div className="item" >
                                <div className="timeline_box complete" >
                                    <div className="timeline_inner" >
                                        <div className="timeline_circle" />
                                        <h6 className="animation" data-animation="fadeInUp" data-animation-delay="0.3s" >August 2021</h6 >
                                        <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s" >Genesis Block Mined & The Revolutionary Hybrid Token: MOONI Was Born!</p >
                                    </div >
                                </div >
                            </div >
                            <div className="item" >
                                <div className="timeline_box complete" >
                                    <div className="timeline_inner" >
                                        <div className="timeline_circle" />
                                        <h6 className="animation" data-animation="fadeInUp" data-animation-delay="0.3s" >August 2021</h6 >
                                        <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s" >Initial Token Burn and Distribution</p >
                                    </div >
                                </div >
                            </div >
                            <div className="item" >
                                <div className="timeline_box complete" >
                                    <div className="timeline_inner" >
                                        <div className="timeline_circle" />
                                        <h6 className="animation" data-animation="fadeInUp" data-animation-delay="0.3s" >August 2021</h6 >
                                        <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s" >Community Faucet Created & Deployed (Not Funded)</p >
                                    </div >
                                </div >
                            </div >
                            <div className="item" >
                                <div className="timeline_box complete" >
                                    <div className="timeline_inner" >
                                        <div className="timeline_circle" />
                                        <h6 className="animation" data-animation="fadeInUp" data-animation-delay="0.3s" >August 2021</h6 >
                                        <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s" >Quickswap DEX Listing</p >
                                    </div >
                                </div >
                            </div >
                            <div className="item" >
                                <div className="timeline_box complete" >
                                    <div className="timeline_inner" >
                                        <div className="timeline_circle" />
                                        <h6 className="animation" data-animation="fadeInUp" data-animation-delay="0.3s" >August 2021</h6 >
                                        <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s" >GYSR Pool Launched (Rewards Not Activated)</p >
                                    </div >
                                </div >
                            </div >
                            <div className="item" >
                                <div className="timeline_box complete current" >
                                    <div className="timeline_inner" >
                                        <div className="timeline_circle" />
                                        <h6 className="animation" data-animation="fadeInUp" data-animation-delay="0.3s" >October 2021</h6 >
                                        <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s" >Community Faucet Funded</p >
                                    </div >
                                </div >
                            </div >
                            <div className="item" >
                                <div className="timeline_box" >
                                    <div className="timeline_inner" >
                                        <div className="timeline_circle" />
                                        <h6 className="animation" data-animation="fadeInUp" data-animation-delay="0.3s" >TBA</h6 >
                                        <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s" >All Social Media Accounts Active</p >
                                    </div >
                                </div >
                            </div >
                            <div className="item" >
                                <div className="timeline_box" >
                                    <div className="roadmap_inner" >
                                        <div className="timeline_circle" />
                                        <h6 className="animation" data-animation="fadeInUp" data-animation-delay="0.3s" >TBA</h6 >
                                        <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s" >CoinGecko Listing</p >
                                    </div >
                                </div >
                            </div >
                            <div className="item" >
                                <div className="timeline_box" >
                                    <div className="timeline_inner" >
                                        <div className="timeline_circle" />
                                        <h6 className="animation" data-animation="fadeInUp" data-animation-delay="0.3s" >TBA</h6 >
                                        <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s" >CoinMarketCap Listing</p >
                                    </div >
                                </div >
                            </div >
                            <div className="item" >
                                <div className="timeline_box" >
                                    <div className="timeline_inner" >
                                        <div className="timeline_circle" />
                                        <h6 className="animation" data-animation="fadeInUp" data-animation-delay="0.3s" >TBA</h6 >
                                        <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s" >Publish White Paper</p >
                                    </div >
                                </div >
                            </div >
                            <div className="item" >
                                <div className="timeline_box" >
                                    <div className="timeline_inner" >
                                        <div className="timeline_circle" />
                                        <h6 className="animation" data-animation="fadeInUp" data-animation-delay="0.3s" >TBA</h6 >
                                        <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s" >NFT Drop For The Community</p >
                                    </div >
                                </div >
                            </div >
                            <div className="item" >
                                <div className="timeline_box" >
                                    <div className="timeline_inner" >
                                        <div className="timeline_circle" />
                                        <h6 className="animation" data-animation="fadeInUp" data-animation-delay="0.3s" >TBA</h6 >
                                        <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s" >Create Partnerships and Yield Farms</p >
                                    </div >
                                </div >
                            </div >
                            <div className="item" >
                                <div className="timeline_box" >
                                    <div className="timeline_inner" >
                                        <div className="timeline_circle" />
                                        <h6 className="animation" data-animation="fadeInUp" data-animation-delay="0.3s" >TBA</h6 >
                                        <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s" >Next Gen Layer 2 Gaming Project With Mooni as a Utility </p >
                                    </div >
                                </div >
                            </div >
                        </OwlCarousel >
                    </div >
                </div >
            </div >
        </section >
    )
}

export default SectionRoadMap;