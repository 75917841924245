import React from "react";

const SectionLoader = () => {
    return(
        <div id="loader-wrapper" >
            <div id="loading-center-absolute" >
                <div className="object" id="object_four" />
                <div className="object" id="object_three" />
                <div className="object" id="object_two" />
                <div className="object" id="object_one" />
            </div >
            <div className="loader-section section-left" />
            <div className="loader-section section-right" />
        </div >
    )
}

export default SectionLoader;