import Alert from 'react-bootstrap/Alert'

const BalanceMessage = ({ balance, message = 'Balance' }) => {
    return (
      <div>
        <Alert variant="info"> {message}: {balance}</Alert>
      </div>
  )
}

export default BalanceMessage


