import React from "react";
import error404 from "../images/404_img.png";
import banner_Main from "../images/banner_img.jpg";

const Error404 = () => {
    return (
        <section id="home" className="section_banner bg_black_dark" data-z-index="1" data-parallax="scroll"
                 data-image-src={banner_Main} >
            <canvas id="banner_canvas" className="transparent_effect fixed" />
            <div className="container" >
                <div className="row align-items-center" >
                    <div className="col-lg-12 col-md-12 col-sm-12 order-lg-first" >
                        <a className="animation" href='/' data-animation="fadeInDown" data-animation-delay=".5s" >
                            <img className="logo_light" src={error404} title="Error404" alt='404 Error'/>
                        </a >
                    </div >
                </div >
            </div >
        </section >
    )
}

export default Error404;