import React from "react";
import { Link } from "react-scroll";

import footer_bg from "../../images/footer_bg.png";
// import whitePaper from "../../images/whitepaper3.png";
import logo from "../../images/logoColored.png";
import coinMarketCap from "../../images/coinMarketCap.png";
import coingecko from "../../images/coingecko.png";
import coinbase from "../../images/coinbase.png";
import quickswap from "../../images/quickswap.png";
import gysr from "../../images/gysr.png";

const SectionFooter = () => {
    return(
        <footer className="small_pb small_pt ">
            <div className="top_footer" data-z-index="1" data-parallax="scroll" data-image-src={footer_bg} >
                <section id="followUs" >
                    <div className="container" >
                        <div className="row" >
                            <div className="col-lg-8 col-md-12 offset-lg-2" >
                                <div className="title_default_light title_border text-center" >
                                    <h4 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s" >Follow Us</h4 >
                                </div >
                            </div >
                        </div >
                        <br/>
                        <div className="row" >
                            <div className="col-lg-12 col-md-12" >
                                <div className="footer_logo mb-3 animation" data-animation="fadeInUp" data-animation-delay="0.2s" >
                                    <Link  className="page-scroll active" activeClass="active" to="home" spy={true} smooth={true} offset={-70} duration={150} >
                                        <img alt="logo" src={logo} />
                                    </Link >
                                </div >
                            </div >
                        </div >
                        <br/>
                        <div className="row">
                            <div className="home_markets" >
                                <a href="https://coinmarketcap.com/currencies/mooniverse/" rel="noopener noreferrer" target="_blank" data-bcup-haslogintext="no" >
                                    <img src={coinMarketCap} alt="CoinMarketCap" height="auto" width="200" />
                                </a >
                                <a href="https://www.coingecko.com/en/coins/mooniverse" rel="noopener noreferrer" target="_blank" data-bcup-haslogintext="no" >
                                    <img src={coingecko} alt="CoinGecko" height="auto" width="200"/>
                                </a >
                                <a
                                    href="https://www.coinbase.com/price/mooniverse" rel="noopener noreferrer" target="_blank" data-bcup-haslogintext="no" >
                                    <img src={coinbase} alt="Coinbase" height="auto" width="200"/>
                                </a >
                            </div >
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        {/*<div className="title_default_light  text-center" >*/}
                        {/*    <h4 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s" >Visit our Partners:</h4 >*/}
                        {/*</div >*/}
                        <br/>
                        <div className="row align-items-center text-center overflow_hide small_space" >
                            <div className="col-lg-3 col-md-4 col-6 logo_border" >
                                <div className="d-flex flex-wrap align-items-center justify-content-center h-100 animation" data-animation="fadeInUp" data-animation-delay="0.3s" >
                                    <a href={'https://quickswap.exchange/#/swap?outputCurrency=0x1Cf19b5C198B1Fb229C1DB51Ee5FceCc28981f4E'} target={'_blank'} rel='noreferrer' ><img src={quickswap} title={'Quickswap'} alt="Quickswap" height='auto' width={300} /></a> 
                                </div >
                            </div >
                            <div className="col-lg-3 col-md-4 col-6 logo_border" >
                                <div className="d-flex flex-wrap align-items-center justify-content-center h-100 animation" data-animation="fadeInUp" data-animation-delay="0.4s" >
                                    <a href={'https://app.gysr.io/pool/0x8c0af06bdf9dc951589759bfeec6ca920d4597f1'} target={'_blank'} rel='noreferrer' ><img src={gysr} title={'Gysr'} alt="gysr" height='auto' width={210} /></a>
                                </div >
                            </div >
                            <div className="col-lg-3 col-md-4 col-6 logo_border" >
                                <div className="d-flex flex-wrap align-items-center justify-content-center h-100 animation" data-animation="fadeInUp" data-animation-delay="0.5s" >
                                    TBA
                                    {/*<img src={client_logo_wt3} alt="client_logo_wt3" />*/}
                                </div >
                            </div >
                            <div className="col-lg-3 col-md-4 col-6 logo_border" >
                                <div className="d-flex flex-wrap align-items-center justify-content-center h-100 animation" data-animation="fadeInUp" data-animation-delay="0.6s" >
                                    TBA
                                    {/*<img src={client_logo_wt4} alt="client_logo_wt4" />*/}
                                </div >
                            </div >
                            <div className="col-lg-3 col-md-4 col-6 logo_border" >
                                <div className="d-flex flex-wrap align-items-center justify-content-center h-100 animation" data-animation="fadeInUp" data-animation-delay="0.7s" >
                                    TBA
                                    {/*<img src={client_logo_wt5} alt="client_logo_wt5" />*/}
                                </div >
                            </div >
                            <div className="col-lg-3 col-md-4 col-6 logo_border" >
                                <div className="d-flex flex-wrap align-items-center justify-content-center h-100 animation" data-animation="fadeInUp" data-animation-delay="0.8s" >
                                    TBA
                                    {/*<img src={client_logo_wt6} alt="client_logo_wt6" />*/}
                                </div >
                            </div >
                            <div className="col-lg-3 col-md-4 col-6 logo_border" >
                                <div className="d-flex flex-wrap align-items-center justify-content-center h-100 animation" data-animation="fadeInUp" data-animation-delay="0.8s" >
                                    TBA
                                    {/*<img src={client_logo_wt7} alt="client_logo_wt7" />*/}
                                </div >
                            </div >
                            <div className="col-lg-3 col-md-4 col-6 logo_border" >
                                <div className="d-flex flex-wrap align-items-center justify-content-center h-100 animation" data-animation="fadeInUp" data-animation-delay="0.8s" >
                                    TBA
                                    {/*<img src={client_logo_wt8} alt="client_logo_wt8" />*/}
                                </div >
                            </div >
                        </div >
                        <br/>
                    </div >
                </section>
            </div >
            <div className="bottom_footer bg_light_dark" >
                <div className="container" >
                    <div className="row" >
                        <div className="col-md-6" >
                            <p className="copyright" style={{color: "chartreuse"}} >&copy; 2021 - Mooniverse</p >
                        </div >
                        <div className="col-md-6" >
                            <ul className="list_none social_icon footer_menu">
                                <li><a href="https://twitter.com/0xMooniverse" target={'_blank'} rel='noreferrer' ><i className="fa fa-twitter"/></a></li >
                                <li><a href="https://www.reddit.com/r/0xMooniverse/" target={'_blank'} rel='noreferrer' ><i className="fa fa-reddit"/></a></li >
                                <li><a href="#" ><i className="fa fa-telegram"/></a></li >
                                <li><a href="#" ><i className="fa fa-facebook"/></a></li >
                            </ul >
                        </div >
                    </div >
                </div >
            </div >
        </footer >
    )
}

export default SectionFooter;