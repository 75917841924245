import React from "react";
import { Link } from "react-scroll";
import logo from "../../images/logo.png";

const SectionHeader = () => {
    return (
        <header className="header_wrap fixed-top nav-unfixed" >
            <div className="container-fluid" >
                <nav className="navbar navbar-expand-lg" >
                    <Link className="navbar-brand nav-link nav_item page-scroll animation active" to="home" spy={true} smooth={true} offset={-70} duration={150}  data-animation="fadeInDown" data-animation-delay="1s" >
                        <img className="logo_light" src={logo} title="0xMooniverse" alt=" " height="63" width="135"/>
                        <img className="logo_dark"  src={logo} title="0xMooniverse" alt=" " height="63" width="135"/>
                    </Link >
                    <a style={{color: '#ffffff'}} className="navbar-toggler animation default" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
                            data-animation="fadeInDown" data-animation-delay="1.1s" >
                        <span className="ion-android-menu default" />
                    </a >
                    <div className="collapse navbar-collapse" id="navbarSupportedContent" >
                        <ul className="navbar-nav m-auto" >
                            <li className="animation" data-animation="fadeInDown" data-animation-delay="1.2s" >
                                <Link className="nav-link page-scroll nav_item inactive" activeClass="active" to="home" spy={true} smooth={true} offset={-70} duration={150} >Home</Link >
                            </li >
                            <li className="animation" data-animation="fadeInDown" data-animation-delay="1.3s" >
                                <Link className="nav-link page-scroll nav_item" activeClass="active" to="tokenomics" spy={true} smooth={true} offset={-70} duration={150} >Tokenomics</Link >
                            </li >
                            <li className="animation" data-animation="fadeInDown" data-animation-delay="1.4s" >
                                <Link className="nav-link page-scroll nav_item" activeClass="active" to="about" spy={true} smooth={true} offset={-70} duration={150} >About</Link >
                            </li >
                            <li className="animation" data-animation="fadeInDown" data-animation-delay="1.5s" >
                                <Link className="nav-link page-scroll nav_item" activeClass="active" to="distribution" spy={true} smooth={true} offset={-70} duration={150} >Distribution</Link >
                            </li >
                            <li className="animation" data-animation="fadeInDown" data-animation-delay="1.6s" >
                                <Link className="nav-link page-scroll nav_item" activeClass="active" to="roadmap" spy={true} smooth={true} offset={-70} duration={150} >RoadMap</Link >
                            </li >
                            <li className="animation" data-animation="fadeInDown" data-animation-delay="1.7s" >
                                <Link className="nav-link page-scroll nav_item" activeClass="active" to="faq" spy={true} smooth={true} offset={-70} duration={150} >FAQ</Link >
                            </li >
                            <li className="animation" data-animation="fadeInDown" data-animation-delay="1.8s" >
                                <Link className="nav-link page-scroll nav_item" activeClass="active" to="news" spy={true} smooth={true} offset={-70} duration={150} >News</Link >
                            </li >
                            <li className="animation" data-animation="fadeInDown" data-animation-delay="1.9s" >
                                <Link className="nav-link page-scroll nav_item" activeClass="active" to="faucet" spy={true} smooth={true} offset={-70} duration={150} >Faucet</Link >
                            </li >
                            <li className="animation" data-animation="fadeInDown" data-animation-delay="2.0s" >
                                <Link className="nav-link page-scroll nav_item" activeClass="active" to="tokenStats" spy={true} smooth={true} offset={-70} duration={150} >Stats</Link >
                            </li >
                            <li className="animation" data-animation="fadeInDown" data-animation-delay="2.1s" >
                                <Link className="nav-link page-scroll nav_item" activeClass="active" to="followUs" spy={true} smooth={true} offset={-70} duration={150} >Follow</Link >
                            </li >
                        </ul >
                        <ul className="navbar-nav nav_btn align-items-center" >
                            <li className="animation" data-animation="fadeInDown" data-animation-delay="2.2s" ><a className="btn btn-default btn-radius nav_item" href='https://quickswap.exchange/#/swap?outputCurrency=0x1Cf19b5C198B1Fb229C1DB51Ee5FceCc28981f4E' target="_blank" rel='noreferrer' >Buy Now!</a ></li >
                        </ul >
                    </div >
                </nav >
            </div >
        </header >
    )
}

export default SectionHeader;