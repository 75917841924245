// import whitePaper from "../../images/whitepaper.png";
import polygonLogo from "../../images/polygonLogoWithText.png";
import mooniverseText from "../../images/mooniverseText.png";
import banner_img2 from "../../images/logo3.png";
import banner_Main from "../../images/banner_img.jpg";
// import logoNoChip from "../../images/Logo5.png";
import React from "react";

const SectionHome = () => {
    return(
        <section id="home" className="section_banner bg_black_dark" data-z-index="1" data-parallax="scroll" data-image-src={banner_Main} >
            <canvas id="banner_canvas" className="transparent_effect fixed" />
            <div className="container" >
                <div className="row align-items-center" >
                    <div className="col-lg-12 col-md-12 col-sm-12 order-lg-first" >
                        <div className="banner_text_s2 text_md_center align-content-center" >
                            <h1 className="animation text-white" data-animation="fadeInUp" data-animation-delay="1.1s" >
                                <strong >
                                    <img className='res_lg_pr_HeaderLogo' src={banner_img2} height="275px" alt=""/> <br/> <br/>
                                    <img height="auto" width="850" src={mooniverseText} alt=""/> 
                                    <br/><br/> 
                                    <span>Hybrid.<br/><br/>Inflationary.<br/><br/>Deflationary.<br/><br/>Revolutionary!<br/><br/>Built on </span>
                                    <img src={polygonLogo} height="auto" width="215" alt=""/> 
                                </strong >
                            </h1 >
                            <div className="btn_group pt-2 pb-3 animation" data-animation="fadeInUp" data-animation-delay="1.4s" >
                                {/*todo readd the whitepaper*/}
                                {/*<a href="#whitepaper" className="btn btn-border btn-radius" >Whitepaper <i className="ion-ios-arrow-thin-right" /></a >*/}
                                <a href="https://quickswap.exchange/#/swap?outputCurrency=0x1Cf19b5C198B1Fb229C1DB51Ee5FceCc28981f4E" target="_blank" rel='noreferrer' className="btn btn-border btn-radius" >Buy MOONIVERSE <i className="ion-ios-arrow-thin-right" /></a >
                            </div >
                        </div >
                    </div >
                </div >
            </div >
        </section >
    )
}

export default SectionHome;