import Alert from "react-bootstrap/Alert";

const MessageForSend = ({ message, success = true }) => {
    return (
        <div>
            <Alert variant={success ? 'info' : 'danger'}> {message}</Alert>
        </div>
    )
}

export default MessageForSend