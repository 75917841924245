import React from "react";
import {MooniverseProps} from "../../js/App";
import {ethers} from "ethers";
import MooniverseABI from "../Faucet/artifacts/contracts/MooniverseABI.json";
import MooniUsdcQuickswapABI from "../Faucet/artifacts/contracts/Mooni_Usdc_LP_ABI.json";

const SectionTokenStats = () => {
    const [getLiveData, setGetLiveData] = React.useState(true);

    // Token Stats
    const [originalSupply, setOriginalSupply] = React.useState(1000000000000);
    const [totalSupply, setTotalSupply] = React.useState('TBA');
    const [circulatingSupply, setCirculatingSupply] = React.useState('TBA');
    const [burnedSupply, setBurnedSupply] = React.useState('TBA');
    const [totalMinted, setTotalMinted] = React.useState('TBA');
    const [burnPercentage, setBurnPercentage] = React.useState('TBA');
    const [communityPercentage, setCommunityPercentage] = React.useState('TBA');
    const [burnSwitch, setBurnSwitch] = React.useState('TBA');
    const [burnDistribution, setBurnDistribution] = React.useState('TBA');

    // Token Prices
    const [usdPrice, setUsdPrice] = React.useState('TBA');
    const [marketCap, setMarketCap] = React.useState('TBA');
    const [day24HourLow, setDay24HourLow] = React.useState('TBA');
    const [day24HourHigh, setDay24HourHigh] = React.useState('TBA');
    // const [change24Hour, setChange24Hour] = React.useState('TBA');
    
    // Gets our faucets balance
    async function getLiveTokenStats() {
        try {
            const url = MooniverseProps.REACT_APP_POLYGON_RPC_API_Key;
            const network = {chainId: parseInt(MooniverseProps.REACT_APP_CHAIN_ID)};
            const customHttpProvider = new ethers.providers.JsonRpcProvider(url, network);
            const contract = new ethers.Contract(MooniverseProps.REACT_APP_MOONIVERSE_CONTRACT, MooniverseABI, customHttpProvider)
            const quickSwapMooniUsdcLPContract = new ethers.Contract(MooniverseProps.REACT_APP_MOONI_USDC_QUICKSWAP_LP_CONTRACT, MooniUsdcQuickswapABI, customHttpProvider)

            // Getting token info from contract
            const tmpTotalSupply = await contract.totalSupply();
            const tmpCirculatingSupply = await contract.totalCirculatingSupply();
            const tmpBurnedSupply = getDeterminedValues(tmpTotalSupply, tmpCirculatingSupply)
            const isBurnExcluded = await contract.isExcluded(MooniverseProps.REACT_APP_BURN_WALLET);
            const tmpBurnSwitch = await contract.minimumCirculatingSupply();
            const quickSwapLPReserves = await quickSwapMooniUsdcLPContract.getReserves();
            
            let burnRewardDistribution = 0;
            if (isBurnExcluded) {
                const burnReflectionBalance = await contract.getRBalance(MooniverseProps.REACT_APP_BURN_WALLET);
                const burnTokenFromReflectionBalance = await contract.tokenFromReflection(burnReflectionBalance);
                burnRewardDistribution = convertNumberToReadableString(convertBigNumber(burnTokenFromReflectionBalance, 9) - tmpBurnedSupply).slice(0, -10);
            }

            // const tmpBurnedSupply = await contract.getBurnedSupply();
            const tmpTotalMinted = await contract._tMintTotal();

            // Converting info to readable data 
            const totalSupply = convertNumberToReadableString(tmpTotalSupply).slice(0, -10);
            const circulatingSupply = convertNumberToReadableString(tmpCirculatingSupply).slice(0, -10);
            const burnedSupply = convertNumberToReadableString(tmpBurnedSupply).slice(0, -10);
            const totalMinted = convertNumberToReadableString(tmpTotalMinted).slice(0, -10);
            const burnSwitch = convertNumberToReadableString(tmpBurnSwitch).slice(0, -10);
            const {burnPercentage, communityPercentage} = getTaxRates(tmpTotalSupply, tmpBurnedSupply);
            const {marketCapString, usdPriceString} = getMarketCapAndPrice(quickSwapLPReserves._reserve0, quickSwapLPReserves._reserve1)

            // Setting our state
            setTotalSupply(totalSupply);
            setCirculatingSupply(circulatingSupply);
            setBurnedSupply(burnedSupply);
            setTotalMinted(totalMinted);
            setBurnPercentage(burnPercentage);
            setCommunityPercentage(communityPercentage);
            setBurnSwitch(burnSwitch);
            setBurnDistribution(burnRewardDistribution);
            setUsdPrice(usdPriceString);
            setMarketCap(marketCapString);
            setGetLiveData(false);
        }catch (ex) {
            // Setting our state
            setTotalSupply('TBA');
            setCirculatingSupply('TBA');
            setBurnedSupply('TBA');
            setTotalMinted('TBA');
            setBurnPercentage('TBA');
            setCommunityPercentage('TBA');
            setBurnSwitch('TBA');
            setBurnDistribution('TBA');
            setUsdPrice('TBA');
            setMarketCap('TBA');
            setGetLiveData(false);
        }

    }
    
    /// Creates a nicely formatted number to display with 9 decimals
    const convertNumberToReadableString = (bigNumber, decimals = 9) => {
        if(bigNumber){
            let balanceString = bigNumber.toString();
            let updatedBigNumber = balanceString.slice(0, balanceString.length - decimals) + '.' + balanceString.slice(-decimals, balanceString.length);

            return convertBigNumber(updatedBigNumber, decimals).toLocaleString('en', {minimumFractionDigits: decimals});
        }
    }

    /// Takes usdc and mooni quantity from LP as big numbers and returns the market cap and usd price
    const getMarketCapAndPrice = (mooniBigNumber, usdcBigNumber) => {
        if(mooniBigNumber && usdcBigNumber){
            let mooniDecimals = 6;
            let usdcDecimals = 3;
            let mooniBalanceString = mooniBigNumber.toString();
            let usdcBalanceString = usdcBigNumber.toString();

            let mooniAsNumber = convertBigNumber(mooniBalanceString.slice(0, mooniBalanceString.length - mooniDecimals), 3);
            let usdcAsNumber = convertBigNumber(usdcBalanceString.slice(0, usdcBalanceString.length - usdcDecimals), 3);

            let marketCapNumber = usdcAsNumber / mooniAsNumber;
            let usdcPriceNumber = marketCapNumber * mooniAsNumber;

            let marketCapString = convertNumberToReadableString((usdcPriceNumber / 1000).toFixed(2).toString(), 0);
            let usdPriceString = (usdcAsNumber / mooniAsNumber).toFixed(11).toString();

            return {marketCapString: '$' + marketCapString, usdPriceString: '$' + usdPriceString}
        }
    }
    
    // Gets other live stats so we can save RPC calls
    const getDeterminedValues = (totalSupply, circulatingSupply) => totalSupply - convertBigNumber(circulatingSupply, 9);
    
    /// Converts a big number into a non breaking readable number
    const convertBigNumber = (bigNumber, decimals) => Number(parseFloat(bigNumber).toFixed(decimals));

    /// Creates a nicely formatted number to display with 9 decimals
    const getTaxRates = (totalSupply, burnedSupply) => {
        const burnRate = convertBigNumber(burnedSupply, 5) / convertBigNumber(totalSupply, 5);
        const communityRate = convertBigNumber(1, 5) - convertBigNumber(burnRate, 5);
        const burnPercentage = `${burnRate.toFixed(4)}%`;
        const communityPercentage = `${communityRate.toFixed(4)}%`;

        return {burnPercentage: burnPercentage, communityPercentage: communityPercentage};
    }
    
    React.useEffect(async () => {
        if(getLiveData)
            await getLiveTokenStats()
    }, [getLiveData]);

    return (
        <section id="tokenStats" className="small_pb" >
            <div className="container " >
                <div className="row align-items-center" >
                    <div className="col-lg-12 col-md-12 col-sm-12 order-lg-first" >
                        <div className="row" >
                            <div className="col-lg-8 col-md-12 offset-lg-2" >
                                <div className="title_default_light title_border text-center" >
                                    <h4 className="animation" data-animation="fadeInUp"
                                        data-animation-delay="0.2s" >Live Stats</h4 >
                                </div >
                            </div >
                        </div >
                        <div className="col-lg-12 col-md-12 col-sm-12" >
                            <div  className=" title_border text-center home_infoBox" >
                                <div >
                                    <strong className="tokenStatsHeader">Supply</strong >
                                    <p className="tokenStats" >{totalSupply}</p >
                                </div >
                                {/*<div >*/}
                                {/*    <strong className="tokenStatsHeader" >OG Supply</strong >*/}
                                {/*    <p className="tokenStats" ><b>1 Trillion</b></p >*/}
                                {/*</div >*/}
                                <div >
                                    <strong className="tokenStatsHeader">Circulating</strong >
                                    <p className="tokenStats" >{circulatingSupply}</p >
                                </div >
                                <div >
                                    <strong className="tokenStatsHeader">Mined</strong >
                                    <p className="tokenStats" >{totalMinted}</p >
                                </div >
                                <div >
                                    <strong className="tokenStatsHeader">Burned</strong >
                                    <p className="tokenStats" >{burnedSupply}</p >
                                </div >
                                
                                <div >
                                    <strong className="tokenStatsHeader">Burn %</strong >
                                    <p className="tokenStats" >{burnPercentage}</p >
                                </div >
                                <div >
                                    <strong className="tokenStatsHeader">Hodl % </strong >
                                    <p className="tokenStats" >{communityPercentage}</p >
                                </div >
                                <div >
                                    <strong className="tokenStatsHeader">Burn Switch </strong >
                                    <p className="tokenStats" >{burnSwitch}</p >
                                </div >
                                <div >
                                    <strong className="tokenStatsHeader">$USD</strong >
                                    <p className="tokenStats" >{usdPrice}</p >
                                </div >
                                <div >
                                    <strong className="tokenStatsHeader">Market Cap</strong >
                                    <p className="tokenStats" >{marketCap}</p >
                                </div >
                                <div >
                                    <strong className="tokenStatsHeader">B/S Rewards</strong >
                                    <p className="tokenStats" >{burnDistribution}</p >
                                </div >
                                <div >
                                    <strong className="tokenStatsHeader">24H Low</strong >
                                    <p className="tokenStats" >{day24HourLow}</p >
                                </div >
                                <div >
                                    <strong className="tokenStatsHeader">24H High</strong >
                                    <p className="tokenStats" >{day24HourHigh}</p >
                                </div >
                                {/*<div >*/}
                                {/*    <strong className="tokenStatsHeader">24H Change</strong >*/}
                                {/*    <p className="tokenStats" >{change24Hour}</p >*/}
                                {/*</div >*/}
                            </div >
                        </div >
                    </div >
                </div >
            </div >
            <br/>
            <br/>
            <br/>
        </section >
    )
}

export default SectionTokenStats;