import React from "react";
import tokenDistribution from "../../images/MooniverseDistributionChart.png";


const SectionTokenDistribution = () => {
    return (
        <section id="distribution" className="section_token">
            <div className="container" >
                <div className="row" >
                    <div className="col-lg-12 col-md-12 col-sm-12" >
                        <div className="title_default_light title_border text-center" >
                            <h4 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s" >Distribution</h4 >
                        </div >
                        <div className="lg_pt_30 res_sm_pt_0 text-center animation" data-animation="fadeInLeft" data-animation-delay="0.2s" >
                            <img src={tokenDistribution} title={'Token Distribution Chart'} alt="Token Distribution Chart"  />
                        </div >
                    </div >
                </div >
            </div >
        </section >
    );
}

export default SectionTokenDistribution;