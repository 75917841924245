import React from "react";
import {MooniverseProps} from "../../js/App";

const SectionFAQ = () => {
    return(
        <section id="faq" className="" >
            <div className="container" >
                <div className="row" >
                    <div className="col-lg-8 col-md-12 offset-lg-2" >
                        <div className="title_default_light title_border text-center" >
                            <h4 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s" >FAQ</h4 >
                            <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s" >
                               Where we try to answer all questions about Mooniverse and existing deflationary reflection tokens
                            </p >
                        </div >
                    </div >
                </div >
                <div className="row small_space" >
                    <div className="col-lg-12 col-md-12" >
                        <div className="tab_content" >
                            <ul className="nav nav-pills tab_nav_s2 tab_color_white justify-content-center" id="pills-tab" role="tablist" >
                                <li className="nav-item animation" data-animation="fadeInUp" data-animation-delay="0.5s" >
                                    <a className="active" data-toggle="tab" href="#tab1" >General</a >
                                </li >
                                <li className="nav-item animation" data-animation="fadeInUp" data-animation-delay="0.6s" >
                                    <a data-toggle="tab" href="#tab2" >Inflationary</a >
                                </li >
                                <li className="nav-item animation" data-animation="fadeInUp" data-animation-delay="0.7s" >
                                    <a data-toggle="tab" href="#tab3" >Deflationary</a >
                                </li >
                                <li className="nav-item animation" data-animation="fadeInUp" data-animation-delay="0.8s" >
                                    <a data-toggle="tab" href="#tab4" >Mining</a >
                                </li >
                            </ul >
                            <div className="tab-content half_tab" >
                                <div className="tab-pane fade show active" id="tab1" role="tabpanel" >
                                    <div className="row" >
                                        <div className="col-md-6" >
                                            <div id="accordion1" className="faq_content" >
                                                <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.4s" >
                                                    <div className="card-header" id="headingOne" >
                                                        <h6 className="mb-0" ><a data-toggle="collapse" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne" >What is Mooniverse?</a ></h6 >
                                                    </div >
                                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion1" >
                                                        <div className="card-body" >
                                                            Mooniverse is an evolved hybrid smart contract built on the Polygon network and was created from the original reflection code.
                                                            Unlike other reflection tokens, where the supply will eventually go to near 0 (see Zeno's Paradox), Mooniverse was designed for an ever-growing economy
                                                            and has a built-in fail safe to ensure that the circulating supply is always above a set threshold.
                                                        </div >
                                                    </div >
                                                </div >
                                                <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.6s" >
                                                    <div className="card-header" id="headingTwo" >
                                                        <h6 className="mb-0" ><a className="collapsed" data-toggle="collapse" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" >Transaction Fees</a ></h6 >
                                                    </div >
                                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion1" >
                                                        <div className="card-body" >
                                                            Mooniverse has a 2% fee that is taken from every transaction and distributed among all Mooniverse hodlers.
                                                        </div >
                                                    </div >
                                                </div >
                                                <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.8s" >
                                                    <div className="card-header" id="headingThree" >
                                                        <h6 className="mb-0" ><a className="collapsed" data-toggle="collapse" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree" >Why the 2% Fee?</a ></h6 >
                                                    </div >
                                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion1" >
                                                        <div className="card-body" > 
                                                            The 2% fee seemed fair for a number of reasons. For starters, because the burn wallet is still a "wallet", and therefore a part of 
                                                            the community, it will always get its share of the fee which will always be 48% or more based on its Token Share % at the time of fee distribution.
                                                            On top of this, anything more than 2% really feels like you're stealing from the community which isn't what Mooniverse is about.
                                                            At first the community with receive about 52% of the 2% fee but will receive less as the burn wallet grows. 
                                                        </div >
                                                    </div >
                                                </div >
                                                <div className="card animation" data-animation="fadeInUp" data-animation-delay="1s" >
                                                    <div className="card-header" id="headingFour" >
                                                        <h6 className="mb-0" ><a className="collapsed" data-toggle="collapse" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour" >Why the burn?</a ></h6 >
                                                    </div >
                                                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion1" >
                                                        <div className="card-body" > 
                                                            The burn mechanism and burn wallet are an essential part of any reflection token, without it the token wouldn't be deflationary. By starting with a 
                                                            larger supply and burning tokens to decrease the supply with every transaction, it is possible to significantly increase hodlers and 
                                                            adoption while the price is lower and more affordable. With increasing trading volume, hodlers will gain more and more interest by holding Mooniverse 
                                                            as they will receive their share of the fee from every trade.
                                                        </div >
                                                    </div >
                                                </div >
                                                <div className="card animation" data-animation="fadeInUp" data-animation-delay="1.1s" >
                                                    <div className="card-header" id="headingFourteen" >
                                                        <h6 className="mb-0" ><a className="collapsed" data-toggle="collapse" href="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen" >What happens when the burn wallet has a 99% token share?</a ></h6 >
                                                    </div >
                                                    <div id="collapseFourteen" className="collapse" aria-labelledby="headingFourteen" data-parent="#accordion4" >
                                                        <div className="card-body" >
                                                            Short answer: 99% of all fees and mined tokens will be burned. <br/> Better and longer answer: Mooniverse was designed with the future in mind, and thus, has a built-in mechanism to ensure the
                                                            minimum circulating supply can be increased as well as maintained. This is done by excluding the burn wallet. When this happens, the burn wallet will essentially be given a snap shot of its current
                                                            number of tokens and while the burn wallet will still accumulate tokens, they are not considered burned and are still apart of the circulating supply. Once the new minimum circulating supply is reached,
                                                            the protocol will automatically transfer the difference the burn wallet has accumulated to the Mooniverse faucet in order to redistribute the tokens amongst the community which will also reduce the
                                                            burn wallets token share %.
                                                        </div >
                                                    </div >
                                                </div >
                                            </div >
                                        </div >
                                        <div className="col-md-6" >
                                            <div id="accordion2" className="faq_content" >
                                                <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.4s" >
                                                    <div className="card-header" id="headingFive" >
                                                        <h6 className="mb-0" ><a data-toggle="collapse" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive" >What about Zeno's Paradox?</a ></h6 >
                                                    </div >
                                                    <div id="collapseFive" className="collapse show" aria-labelledby="headingFive" data-parent="#accordion2" >
                                                        <div className="card-body" > 
                                                            Because the smart contract was designed with a "Burn Switch" for the burn wallet and also mints new tokens with every transaction, Mooniverse is able to bypass
                                                            Zeno's Paradox since the contract prevents the circulating supply from falling and staying below the set amount that is governed by the protocol.
                                                        </div >
                                                    </div >
                                                </div >
                                                <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.6s" >
                                                    <div className="card-header" id="headingSix" >
                                                        <h6 className="mb-0" ><a className="collapsed" data-toggle="collapse" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix" >What is the "Burn Switch"?</a ></h6 >
                                                    </div >
                                                    <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordion2" >
                                                        <div className="card-body" > 
                                                            Reflection contracts have a way to include or exclude accounts from "receiving" interest tokens. Most of these claim that by excluding the burn wallet, it will no longer
                                                            receive any interest tokens thereby giving the community the full distribution of the fees instead. Unfortunately, this is false. While excluded, the burn wallet still receives 
                                                            interest tokens and most contracts allow the included wallets to keep the earned interest or just remove it from the supply all together.
                                                            However, Mooniverse's "Burn Switch" allows the contract to decide when to exclude and include the burn wallet automatically and once included the difference in the amount 
                                                            of tokens it should have and the amount it actually has with interest is subtracted and sent to directly to the contract to be given back to the community via the 
                                                            Mooniverse Faucet. 
                                                        </div >
                                                    </div >
                                                </div >
                                                <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.8s" >
                                                    <div className="card-header" id="headingSeven" >
                                                        <h6 className="mb-0" ><a className="collapsed" data-toggle="collapse" href="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven" >Why the Faucet?</a ></h6 >
                                                    </div >
                                                    <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordion2" >
                                                        <div className="card-body" >
                                                            To bring the tokenomics to life, Mooniverse has a built-in faucet that the community can access once per day to top off their supply or to join the Mooniverse family.
                                                            The faucet was created to facilitate the redistribution of the fees from the "Burn Switch" in order to give the tokens back to the community freely.
                                                        </div >
                                                    </div >
                                                </div >
                                                <div className="card animation" data-animation="fadeInUp" data-animation-delay="1s" >
                                                    <div className="card-header" id="headingEight" >
                                                        <h6 className="mb-0" ><a className="collapsed" data-toggle="collapse" href="#collapseEight" aria-expanded="false" aria-controls="collapseEight" >What makes Mooniverse Revolutionary?</a ></h6 >
                                                    </div >
                                                    <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-parent="#accordion2" >
                                                        <div className="card-body" >
                                                            Unlike other reflection contracts, Mooniverse is a hybrid Deflationary/Inflationary token. As trading volume is high, the supply will decrease as more and more tokens are burned but when trading
                                                            volume decreases due to Bitcoin, FUD, or a bear market, the supply is able to increase through the revolutionary token mining process built into the protocol. This not only encourages users to
                                                            continue to hodl, but also encourages the community to interact with Mooniverse in order to trigger the token mining process which gives the whole community more tokens! The reason this is important
                                                            is because as the crypto verse continues to expand and gain more and more adoption it is necessary to have a cryptocurrency that can meet the demands of the economy and can supply enough to
                                                            fill the required demand. Having only 1-21 million tokens isn't adequate when there are 7+ Billion people on the planet and main stream adoption is imminent.
                                                        </div >
                                                    </div >
                                                </div >
                                                <div className="card animation" data-animation="fadeInUp" data-animation-delay="1.1s" >
                                                    <div className="card-header" id="heading25" >
                                                        <h6 className="mb-0" ><a className="collapsed" data-toggle="collapse" href="#collapse25" aria-expanded="false" aria-controls="collapse25" >What is the token name, symbol and contract address?</a ></h6 >
                                                    </div >
                                                    <div id="collapse25" className="collapse" aria-labelledby="heading25" data-parent="#accordion4" >
                                                        <div className="card-body" >
                                                            The name of the protocol is officially 0xMooniverse but you will see it used interchangeably with Mooniverse as well. The symbol and name used for the token itself is MOONI, short for Mooniverse, and the contract address is:<br/>
                                                            {MooniverseProps.REACT_APP_MOONIVERSE_CONTRACT}
                                                        </div >
                                                    </div >
                                                </div >
                                            </div >
                                        </div >
                                    </div >
                                </div >
                                <div className="tab-pane fade" id="tab2" role="tabpanel" >
                                    <div className="row" >
                                        <div className="col-md-6" >
                                            <div id="accordion3" className="faq_content" >
                                                <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.4s" >
                                                    <div className="card-header" id="headingNine" >
                                                        <h6 className="mb-0" ><a data-toggle="collapse" href="#collapseNine" aria-expanded="false" aria-controls="collapseNine" >Why inflate the supply?</a ></h6 >
                                                    </div >
                                                    <div id="collapseNine" className="collapse show" aria-labelledby="headingNine" data-parent="#accordion3" >
                                                        <div className="card-body" >
                                                            One of the major flaws and drawbacks with reflection tokens or tokens with a perpetual burn mechanism, is that eventually the available circulating supply will become too
                                                            low to make it viable for anything. Let’s say a token reaches mass adoption and trading volume, what happens when that tokens supply is finally under 1 available token?
                                                            This is the reason why it's so important to be able to inflate the supply. Life happens, and tokens can be lost by being sent to invalid addresses, by being burned
                                                            and from losing access to a wallet. By inflating the supply, Mooniverse is able to replenish the lost supply of tokens in order to keep itself as a continuous viable
                                                            option for years to come.
                                                        </div >
                                                    </div >
                                                </div >
                                                <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.6s" >
                                                    <div className="card-header" id="headingTen" >
                                                        <h6 className="mb-0" ><a className="collapsed" data-toggle="collapse" href="#collapseTen" aria-expanded="false" aria-controls="collapseTen" >How does inflation help trading and involvement?</a ></h6 >
                                                    </div >
                                                    <div id="collapseTen" className="collapse" aria-labelledby="headingTen" data-parent="#accordion3" >
                                                        <div className="card-body" > 
                                                            During times of market regression, or bear markets, trading volume for most coins dissipates and people begin to lose hope as well as interest in the market. Without community
                                                            engagement there is little to be done except bide your time, hodl and wait out the current market conditions. Mooniverse aims to keep community interaction by constantly rewarding 
                                                            its members for doing their part by inflating the supply of tokens and distributing them to its members for taking part in the Mooniverse ecosystem.
                                                        </div >
                                                    </div >
                                                </div >
                                                <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.8s" >
                                                    <div className="card-header" id="headingEleven" >
                                                        <h6 className="mb-0" ><a className="collapsed" data-toggle="collapse" href="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven" >Isn't the point to deflate the supply?</a ></h6 >
                                                    </div >
                                                    <div id="collapseEleven" className="collapse" aria-labelledby="headingEleven" data-parent="#accordion3" >
                                                        <div className="card-body" >
                                                            To an extent, yes. But that can only continue for so long. As the crypto sphere grows and gains more and more adoption, it is unrealistic for a long-term cryptocurrency to have a small
                                                            supply of tokens, unless it acts as a sort of governance or utility token, as it will prevent further adoption and use. While it is true, that in the short term it will help,
                                                            Mooniverse aspires for more and looks at not only the present, but at the future to ensure it always has the ever-growing community in mind.
                                                        </div >
                                                    </div >
                                                </div >
                                                
                                            </div >
                                        </div >
                                        <div className="col-md-6" >
                                            <div id="accordion4" className="faq_content" >
                                                <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.4s" >
                                                    <div className="card-header" id="headingThirteen" >
                                                        <h6 className="mb-0" ><a data-toggle="collapse" href="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen" >How much inflation is there?</a ></h6 >
                                                    </div >
                                                    <div id="collapseThirteen" className="collapse show" aria-labelledby="headingThirteen" data-parent="#accordion4" >
                                                        <div className="card-body" >
                                                            Mooniverse inflates the supply by about 20 billion tokens a year, of which a large portion is burned. At first, roughly 12 billion tokens will be added to the circulating supply every year.
                                                            As the burn wallet gains more of the supply and increases its personal token share %, the more it will gain of the generated tokens which, in turn, will slowly reduce the inflation
                                                            amount over time.
                                                        </div >
                                                    </div >
                                                </div >
                                                <div className="card animation" data-animation="fadeInUp" data-animation-delay="1s" >
                                                    <div className="card-header" id="headingTwelve" >
                                                        <h6 className="mb-0" ><a className="collapsed" data-toggle="collapse" href="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve" >Why would people choose an inflationary cryptocurrency?</a ></h6 >
                                                    </div >
                                                    <div id="collapseTwelve" className="collapse" aria-labelledby="headingTwelve" data-parent="#accordion4" >
                                                        <div className="card-body" >
                                                            Why wouldn't you choose a cryptocurrency that literally rewards you for just holding your money? Think of Mooniverse as a high-rate savings/checking account.
                                                            Similar to how banks reward their users for holding their money in the bank accounts so the banks can lend out the money for a profit, of which they give back miniscule amounts,
                                                            as people use Mooniverse more and more in their daily lives, any holder will also receive "interest" for holding Mooniverse in their wallet. This is compounded by not only
                                                            gaining a share of the built-in fee as a profit but by the contract generating and distributing tokens directly to its members every time someone interacts with the protocol.
                                                        </div >
                                                    </div >
                                                </div >
                                                
                                                {/*<div className="card animation" data-animation="fadeInUp" data-animation-delay="0.8s" >*/}
                                                {/*    <div className="card-header" id="headingFifteen" >*/}
                                                {/*        <h6 className="mb-0" ><a className="collapsed" data-toggle="collapse" href="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen" >Title here</a ></h6 >*/}
                                                {/*    </div >*/}
                                                {/*    <div id="collapseFifteen" className="collapse" aria-labelledby="headingFifteen" data-parent="#accordion4" >*/}
                                                {/*        <div className="card-body" > */}
                                                {/*             body here*/}
                                                {/*        </div >*/}
                                                {/*    </div >*/}
                                                {/*</div >*/}
                                                {/*<div className="card animation" data-animation="fadeInUp" data-animation-delay="1s" >*/}
                                                {/*    <div className="card-header" id="headingSixteen" >*/}
                                                {/*        <h6 className="mb-0" ><a className="collapsed" data-toggle="collapse" href="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen" >Title here</a ></h6 >*/}
                                                {/*    </div >*/}
                                                {/*    <div id="collapseSixteen" className="collapse" aria-labelledby="headingSixteen" data-parent="#accordion4" >*/}
                                                {/*        <div className="card-body" >    */}
                                                {/*              body here.      */}
                                                {/*        </div >*/}
                                                {/*    </div >*/}
                                                {/*</div >*/}
                                            </div >
                                        </div >
                                    </div >
                                </div >
                                <div className="tab-pane fade" id="tab3" role="tabpanel" >
                                    <div className="row" >
                                        <div className="col-md-6" >
                                            <div id="accordion5" className="faq_content" >
                                                <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.4s" >
                                                    <div className="card-header" id="headingSeventeen" >
                                                        <h6 className="mb-0" ><a data-toggle="collapse" href="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen" >What is a deflationary/reflection/burn token?</a ></h6 >
                                                    </div >
                                                    <div id="collapseSeventeen" className="collapse show" aria-labelledby="headingSeventeen" data-parent="#accordion5" >
                                                        <div className="card-body" >
                                                            Reflection tokens are just a new addition to the crypto world. You can think of them as another branch of evolution of regular cryptocurrencies. Unlike regular tokens, deflationary
                                                            tokens implement what is known as a "burn mechanism" in order to deflate their total supply. This is achieved through a few steps. First, the burn wallet must be activated
                                                            by sending an initial number of tokens to it. Second, there is a fee taken out of every transaction that is then distributed to all token holders. Third, because the burn wallet
                                                            is considered a wallet and a holder of the token it will get a part of those fees which will effectively burn them.
                                                        </div >
                                                    </div >
                                                </div >
                                                <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.6s" >
                                                    <div className="card-header" id="headingEighteen" >
                                                        <h6 className="mb-0" ><a className="collapsed" data-toggle="collapse" href="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen" >Is it a ponzi or pyramid scheme?</a ></h6 >
                                                    </div >
                                                    <div id="collapseEighteen" className="collapse" aria-labelledby="headingEighteen" data-parent="#accordion5" >
                                                        <div className="card-body" >
                                                            In general, reflection tokens are not a scheme. They are actually an ingenious idea that have caught a lot of attention in 2021. While it is true that the more tokens you hold the more you
                                                            will receive from the distribution of fees, it is also true that you can be rewarded for holding any number of tokens and that you can, for the most part, always buy and accumulate more.
                                                            Unlike a pyramid scheme where only the first few legs really make money, all holders will gain free tokens for holding a reflection token like Mooniverse.
                                                            In actuality, it is better to think of your wallet as a bank account where Mooniverse is the "fiat" or "money" that is stored in it and the reflection/distribution of fees,
                                                            along with the distribution of Mooniverse's revolutionary Token Mining mechanism, is the interest that you will gain over time.
                                                        </div >
                                                    </div >
                                                </div >
                                                {/*<div className="card animation" data-animation="fadeInUp" data-animation-delay="0.8s" >*/}
                                                {/*    <div className="card-header" id="headingNineteen" >*/}
                                                {/*        <h6 className="mb-0" ><a className="collapsed" data-toggle="collapse" href="#collapseNineteen" aria-expanded="false" aria-controls="collapseNineteen" >What determines bitcoin's price?</a ></h6 >*/}
                                                {/*    </div >*/}
                                                {/*    <div id="collapseNineteen" className="collapse" aria-labelledby="headingNineteen" data-parent="#accordion5" >*/}
                                                {/*        <div className="card-body" > The price of a bitcoin is determined by supply and demand. When demand for bitcoins increases, the price increases, and when demand falls, the price falls. There is only*/}
                                                {/*                                     a limited number of bitcoins in circulation and new bitcoins are created at a predictable and decreasing rate*/}
                                                {/*        </div >*/}
                                                {/*    </div >*/}
                                                {/*</div >*/}
                                                {/*<div className="card animation" data-animation="fadeInUp" data-animation-delay="1s" >*/}
                                                {/*    <div className="card-header" id="headingTwenty" >*/}
                                                {/*        <h6 className="mb-0" ><a className="collapsed" data-toggle="collapse" href="#collapseTwenty" aria-expanded="false" aria-controls="collapseTwenty" >Can bitcoins become worthless?</a ></h6 >*/}
                                                {/*    </div >*/}
                                                {/*    <div id="collapseTwenty" className="collapse" aria-labelledby="headingTwenty" data-parent="#accordion5" >*/}
                                                {/*        <div className="card-body" > Yes. History is littered with currencies that failed and are no longer used, such as the German Mark during the Weimar Republic and, more recently, the Zimbabwean*/}
                                                {/*                                     dollar.*/}
                                                {/*        </div >*/}
                                                {/*    </div >*/}
                                                {/*</div >*/}
                                            </div >
                                        </div >
                                        <div className="col-md-6" >
                                            <div id="accordion6" className="faq_content" >
                                                <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.4s" >
                                                    <div className="card-header" id="headingNine" >
                                                        <h6 className="mb-0" ><a data-toggle="collapse" href="#collapse21" aria-expanded="false" aria-controls="collapse21" >What determines the tax/fee?</a ></h6 >
                                                    </div >
                                                    <div id="collapse21" className="collapse show" aria-labelledby="heading21" data-parent="#accordion6" >
                                                        <div className="card-body" > 
                                                            Realistically, the fee, or tax, can be almost anything that the creator of the token wants it to be. It could be very low, such as 1%, or could be very high where its closer to 20-30%. 
                                                        </div >
                                                    </div >
                                                </div >
                                                <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.6s" >
                                                    <div className="card-header" id="heading22" >
                                                        <h6 className="mb-0" ><a className="collapsed" data-toggle="collapse" href="#collapse22" aria-expanded="false" aria-controls="collapse22" >Why am I being taxed to use the token?</a ></h6 >
                                                    </div >
                                                    <div id="collapse22" className="collapse" aria-labelledby="heading22" data-parent="#accordion6" >
                                                        <div className="card-body" >
                                                            Nobody likes being taxed but we all experience it in our lives. Tokens that implement high fees feel like highway robbery and smaller fees may even feel like thievery at first.
                                                            It is important to remember that the fees, or tax, are essentially used to help build and give back to the community since the more trading volume a reflection token has, the
                                                            more rewards the holders of that token will receive.

                                                            That being said, Mooniverse wasn't created to take your hard-earned money, it was created to continuously reward its members for continued interaction with the protocol by
                                                            rewarding members with interest and by generating new tokens, through the first ever communal token mining mechanism that is built directly into the contract,
                                                            that are distributed within the community every time a trade is made in order to help pay you back for the fees you pay when using Mooniverse! <br/>We are Mooniverse, Welcome home.
                                                        </div >
                                                    </div >
                                                </div >
                                                {/*<div className="card animation" data-animation="fadeInUp" data-animation-delay="0.8s" >*/}
                                                {/*    <div className="card-header" id="heading23" >*/}
                                                {/*        <h6 className="mb-0" ><a className="collapsed" data-toggle="collapse" href="#collapse23" aria-expanded="false" aria-controls="collapse23" >What determines bitcoin's price?</a ></h6 >*/}
                                                {/*    </div >*/}
                                                {/*    <div id="collapse23" className="collapse" aria-labelledby="heading23" data-parent="#accordion6" >*/}
                                                {/*        <div className="card-body" > The price of a bitcoin is determined by supply and demand. When demand for bitcoins increases, the price increases, and when demand falls, the price falls. There is only*/}
                                                {/*                                     a limited number of bitcoins in circulation and new bitcoins are created at a predictable and decreasing rate*/}
                                                {/*        </div >*/}
                                                {/*    </div >*/}
                                                {/*</div >*/}
                                                {/*<div className="card animation" data-animation="fadeInUp" data-animation-delay="1s" >*/}
                                                {/*    <div className="card-header" id="heading24" >*/}
                                                {/*        <h6 className="mb-0" ><a className="collapsed" data-toggle="collapse" href="#collapse24" aria-expanded="false" aria-controls="collapse24" >Can bitcoins become worthless?</a ></h6 >*/}
                                                {/*    </div >*/}
                                                {/*    <div id="collapse24" className="collapse" aria-labelledby="heading24" data-parent="#accordion6" >*/}
                                                {/*        <div className="card-body" > Yes. History is littered with currencies that failed and are no longer used, such as the German Mark during the Weimar Republic and, more recently, the Zimbabwean*/}
                                                {/*                                     dollar.*/}
                                                {/*        </div >*/}
                                                {/*    </div >*/}
                                                {/*</div >*/}
                                            </div >
                                        </div >
                                    </div >
                                </div >
                                <div className="tab-pane fade" id="tab4" role="tabpanel" >
                                    <div className="row" >
                                        <div className="col-md-6" >
                                            <div id="accordion7" className="faq_content" >
                                                <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.4s" >
                                                    <div className="card-header" id="heading25" >
                                                        <h6 className="mb-0" ><a data-toggle="collapse" href="#collapse25" aria-expanded="false" aria-controls="collapse25" >What is communal token mining and how does it work?</a ></h6 >
                                                    </div >
                                                    <div id="collapse25" className="collapse show" aria-labelledby="heading25" data-parent="#accordion7" >
                                                        <div className="card-body" >
                                                            The communal token mining mechanism is a core tenant of Mooniverse and also what makes Mooniverse revolutionary! It's built directly into the code so any one can view it. 
                                                            This mechanism is activated when any Mooniverse member trades tokens which will then generate new tokens through the communal token mining process and distribute them among 
                                                            all holders. 
                                                        </div >
                                                    </div >
                                                </div >
                                                <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.6s" >
                                                    <div className="card-header" id="heading26" >
                                                        <h6 className="mb-0" ><a className="collapsed" data-toggle="collapse" href="#collapse26" aria-expanded="false" aria-controls="collapse26" >What can I do with mined tokens?</a ></h6 >
                                                    </div >
                                                    <div id="collapse26" className="collapse" aria-labelledby="heading26" data-parent="#accordion7" >
                                                        <div className="card-body" >
                                                            Any tokens you accumulate through the mining process are yours to do as you please! You can hodl them to earn even more Mooni, you can donate them to the faucet, donate them to charity, send some to your friends and family,
                                                            or you can sell them for a profit!
                                                        </div >
                                                    </div >
                                                </div >
                                                {/*<div className="card animation" data-animation="fadeInUp" data-animation-delay="0.8s" >*/}
                                                {/*    <div className="card-header" id="heading27" >*/}
                                                {/*        <h6 className="mb-0" ><a className="collapsed" data-toggle="collapse" href="#collapse27" aria-expanded="false" aria-controls="collapse27" >Can Bitcoin be regulated?</a ></h6 >*/}
                                                {/*    </div >*/}
                                                {/*    <div id="collapse27" className="collapse" aria-labelledby="heading27" data-parent="#accordion7" >*/}
                                                {/*        <div className="card-body" > The Bitcoin protocol itself cannot be modified without the cooperation of nearly all its users, who choose what software they use. Attempting to assign special rights to*/}
                                                {/*                                     a local authority in the rules of the global Bitcoin network is not a practical possibility.*/}
                                                {/*        </div >*/}
                                                {/*    </div >*/}
                                                {/*</div >*/}
                                                {/*<div className="card animation" data-animation="fadeInUp" data-animation-delay="1s" >*/}
                                                {/*    <div className="card-header" id="heading28" >*/}
                                                {/*        <h6 className="mb-0" ><a className="collapsed" data-toggle="collapse" href="#collapse28" aria-expanded="false" aria-controls="collapse28" >What about Bitcoin and taxes?</a ></h6 >*/}
                                                {/*    </div >*/}
                                                {/*    <div id="collapse28" className="collapse" aria-labelledby="heading28" data-parent="#accordion7" >*/}
                                                {/*        <div className="card-body" > Bitcoin is not a fiat currency with legal tender status in any jurisdiction, but often tax liability accrues regardless of the medium used. There is a wide variety of*/}
                                                {/*                                     legislation in many different jurisdictions which could cause income, sales, payroll, capital gains, or some other form of tax liability to arise with Bitcoin.*/}
                                                {/*        </div >*/}
                                                {/*    </div >*/}
                                                {/*</div >*/}
                                            </div >
                                        </div >
                                        <div className="col-md-6" >
                                            <div id="accordion8" className="faq_content" >
                                                <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.4s" >
                                                    <div className="card-header" id="heading29" >
                                                        <h6 className="mb-0" ><a data-toggle="collapse" href="#collapse29" aria-expanded="false" aria-controls="collapse29" >Why add a communal token mining process into the protocol?</a ></h6 >
                                                    </div >
                                                    <div id="collapse29" className="collapse show" aria-labelledby="heading29" data-parent="#accordion8" >
                                                        <div className="card-body" >
                                                            It was added for a few reasons actually. The biggest one being that we wanted to give back to the community to help compensate the fees you pay for using Mooni and to help with
                                                            the biggest problems that deflationary currencies face which is that most cannot stop the burn of their tokens and the circulating supply will eventually end up close to zero
                                                            or, at the very least, below a useful threshold that can accommodate an ever-growing community. By including this in the protocol, along with a minimum circulating supply check,
                                                            Mooniverse is able to evolve and bypass these issues and position its self for future growth and adoption!
                                                        </div >
                                                    </div >
                                                </div >
                                                {/*<div className="card animation" data-animation="fadeInUp" data-animation-delay="0.6s" >*/}
                                                {/*    <div className="card-header" id="heading30" >*/}
                                                {/*        <h6 className="mb-0" ><a className="collapsed" data-toggle="collapse" href="#collapse30" aria-expanded="false" aria-controls="collapse30" >Is Bitcoin useful for illegal activities?</a ></h6 >*/}
                                                {/*    </div >*/}
                                                {/*    <div id="collapse30" className="collapse" aria-labelledby="heading30" data-parent="#accordion8" >*/}
                                                {/*        <div className="card-body" >*/}
                                                {/*            */}
                                                {/*        </div >*/}
                                                {/*    </div >*/}
                                                {/*</div >*/}
                                                {/*<div className="card animation" data-animation="fadeInUp" data-animation-delay="0.8s" >*/}
                                                {/*    <div className="card-header" id="heading31" >*/}
                                                {/*        <h6 className="mb-0" ><a className="collapsed" data-toggle="collapse" href="#collapse31" aria-expanded="false" aria-controls="collapse31" >Can Bitcoin be regulated?</a ></h6 >*/}
                                                {/*    </div >*/}
                                                {/*    <div id="collapse31" className="collapse" aria-labelledby="heading31" data-parent="#accordion8" >*/}
                                                {/*        <div className="card-body" > The Bitcoin protocol itself cannot be modified without the cooperation of nearly all its users, who choose what software they use. Attempting to assign special rights to*/}
                                                {/*                                     a local authority in the rules of the global Bitcoin network is not a practical possibility.*/}
                                                {/*        </div >*/}
                                                {/*    </div >*/}
                                                {/*</div >*/}
                                                {/*<div className="card animation" data-animation="fadeInUp" data-animation-delay="1s" >*/}
                                                {/*    <div className="card-header" id="heading32" >*/}
                                                {/*        <h6 className="mb-0" ><a className="collapsed" data-toggle="collapse" href="#collapse32" aria-expanded="false" aria-controls="collapse32" >What about Bitcoin and taxes?</a ></h6 >*/}
                                                {/*    </div >*/}
                                                {/*    <div id="collapse32" className="collapse" aria-labelledby="heading32" data-parent="#accordion8" >*/}
                                                {/*        <div className="card-body" > Bitcoin is not a fiat currency with legal tender status in any jurisdiction, but often tax liability accrues regardless of the medium used. There is a wide variety of*/}
                                                {/*                                     legislation in many different jurisdictions which could cause income, sales, payroll, capital gains, or some other form of tax liability to arise with Bitcoin.*/}
                                                {/*        </div >*/}
                                                {/*    </div >*/}
                                                {/*</div >*/}
                                            </div >
                                        </div >
                                    </div >
                                </div >
                            </div >
                        </div >
                    </div >
                </div >
            </div >
        </section >
    )
}

export default SectionFAQ;