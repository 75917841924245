import React from "react";
import service_icon1 from "../../images/FaucetIcon.png";
import Faucet from "../Faucet/Components/Faucet";
import {MooniverseProps} from "../../js/App";

const SectionFaucet = () => {
    return (
        <section id="faucet" className="small_pb small_pt " >
            <div className="container" >
                <div className="row align-items-center" >
                    <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12" >
                        <div className="title_default_light title_border text-center" >
                            <br/>
                            <br/>
                            <h4 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s" >Faucet</h4 >
                            <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s" >
                                <b>Use our faucet to get free tokens and become a part of the 0xMOONIVERSE family today! </b>
                            </p >
                        </div >
                    </div >
                </div >
                <div className="row" >
                    <div className="align-content-center col-lg-12 col-md-12 col-sm-12" >
                        <div className="box_wrap text-center animation" data-animation="fadeInUp" data-animation-delay="0.6s" >
                            <img src={service_icon1} alt="service_icon1" />
                            <h4 >Free Tokens!</h4 >
                            <Faucet />
                        </div >
                    </div >
                </div>
                <div className="row align-items-center" >
                    <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12" >
                        <div style={{overflow: "hidden"}} className="title_default_light title_border text-center" >
                            <h5 style={{fontSize: "1.2vh"}} className="animation" data-animation="fadeInUp" data-animation-delay="0.4s" >
                                If you would like to donate to the faucet,<br/>please send tokens to the 0xMOONIVERSE Contract:<br/> <span style={{fontSize: "1.2vh"}}>{MooniverseProps.REACT_APP_MOONIVERSE_CONTRACT}</span>
                            </h5 >
                        </div >
                    </div >
                </div >
            </div>
        </section>
    )
}

export default SectionFaucet;
